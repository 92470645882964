
/**
 * arches - Global style system focused on utility classes
 * @version v4.1.4x
 * @link https://github.com/ACC-Style/Arches/
 */



/**  Build Comment: including file /setup/__preheader.scss  **/ 
@use "sass:math";
@use "sass:color";

// Consider using https://www.purgecss.com/ to remove styles not used in the project.
/** SCSS DOC: _preheader.scss **/
$debug-trace: false !default;
$name-mode: 'SHORT' !default; // $name-mode: "SHORT","LONG","ALL"
$ms-base: 1rem !default; // needs to be in rem's
$colors: ();
$grays: ();
$theme-palette: ();
$usingFontAwesome:true;
@import 'helpers/helpers';
@import 'vendor/modular-scale';
/** SCSS DOC: _utility-css.vars.scss **/
@import 'setup/config/utility-css.vars';
@import 'helpers/breakpoint';
%font-awesome-pro {
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	font-family: 'Font Awesome 6 Pro', 'Font Awesome 5 Pro';
	font-weight: 900;
}




/**  Build Comment: This Style Sheet should only be used as an augmenting stylesheet  **/ 

$active-class-name: 'active';


/**  Build Comment: Set Active Class  **/ 
/** SCSS DOC: __brand.base.scss **/
/**  Colors  **/

@import 'setup/config/colors';
/**  Fonts  **/
@import 'setup/config/fonts';
/**  Textures  **/
@import 'setup/config/textures';

/**  logos  **/
@import 'setup/config/logo';



// reading-typography
$headline-color: inherit !default;
$reading-min:16px;

/**  CardioSmart Brands    *********************************/
/** SCSS DOC: _brand.cardiosmart.scss **/

/** Set the modular scale to minor third for the lager text treatments.. **/

$ms-ratio: $minor-third;



$teal: #009db1;
$dark-blue: #015697;
$dark-grey: #676a72;
$alt-grey: #a3988c;
$green: #468847;
$yellow: #f5ca4f;
$orange: #e17009;
$red: #953b39;
$alt-orange: #c67605;
$blue: #2b58c0;
$purple: #7742bd;
$magenta: #c34198;
$acc-blue: #004176;
$white: rgb(255, 255, 255);
$black: rgb(19, 18, 18);
$real-black: #000;

$theme-palette: map-merge(
	$theme-palette,
	(
		primary: $dark-blue,
		secondary: $teal,
		success: $green,
		warning: $alt-orange,
		alert: $red,
		//navigation: $blue,
		accent: $orange,
		info: $alt-grey,
		highlight: $yellow,
		acc: $acc-blue,
	)
);

$primary: $dark-blue;
$secondary: $teal;
$success: $green;
$info: $alt-grey;
$warning:  $alt-orange;
$danger: $red;

// Set the active state for this framework & brand
$active-class-name: 'active';
$style-definition: map-deep-set($style-definition, 'is-active' 'short-name', '#{$active-class-name}>.a\\3A ');
$style-definition: map-deep-set($style-definition, 'is-active-self' 'short-name', '#{$active-class-name}.a\\3A ');
$fonts: (
    accent: (
        name: 'Roboto Slab',
        short-name:"RS",
        stack: '"Roboto Slab","Times New Roman","Lucida Bright",Georgia,serif, "slab"',
        weight:(
			light:200,
			regular:400,
			medium:500,
			bold:700,
			xbold:900
		)
        ),
    display: (
        name: 'Maven Pro',
        short-name:"MP",
        stack: '"Maven Pro","Muli","Open Sans",Verdana,Helvetica,sans-serif',
        weight:(
			light:400,
			regular:450,
			medium:550,
			bold:700,
			xbold:900
		)
    ),
    copy: (
        name: 'Open Sans',
        short-name:"OS",
        stack: '"Open Sans",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        weight:(
			light:300,
			regular:400,
			medium:500,
			bold:675,
			xbold:800
		)
        ),
    ui: (
        name: 'Open Sans',
        short-name:"OS",
        stack: '"Open Sans",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        weight:(
			light:300,
			regular:400,
			medium:500,
			bold:675,
			xbold:800
		)
    ),

);
// Reset Values of Utlity-css.vars.scss
$value-to-unit: map-deep-set($value-to-unit, font-family accent, unquote(map-deep-get($fonts, accent, stack)));
$value-to-unit: map-deep-set($value-to-unit, font-family display, unquote(map-deep-get($fonts, display, stack)));
$value-to-unit: map-deep-set($value-to-unit, font-family copy, unquote(map-deep-get($fonts, copy, stack)));
$value-to-unit: map-deep-set($value-to-unit, font-family ui, unquote(map-deep-get($fonts, ui, stack)));


/** Override the fonts sizes. **/
$value-to-unit: map-deep-set($value-to-unit, font-size 0, strip-unit(ms(0,$ms-base,$ms-ratio)) * 1rem);
$value-to-unit: map-deep-set($value-to-unit, font-size 1, strip-unit(ms(1,$ms-base,$ms-ratio)) * 1rem);
$value-to-unit: map-deep-set($value-to-unit, font-size 2, strip-unit(ms(2,$ms-base,$ms-ratio)) * 1rem);
$value-to-unit: map-deep-set($value-to-unit, font-size 3, strip-unit(ms(3,$ms-base,$ms-ratio)) * 1rem);
$value-to-unit: map-deep-set($value-to-unit, font-size 4, strip-unit(ms(4,$ms-base,$ms-ratio)) * 1rem);
$value-to-unit: map-deep-set($value-to-unit, font-size 5, strip-unit(ms(5,$ms-base,$ms-ratio)) * 1rem);
$value-to-unit: map-deep-set($value-to-unit, font-size 6, strip-unit(ms(6,$ms-base,$ms-ratio)) * 1rem);
$value-to-unit: map-deep-set($value-to-unit, font-size 7, strip-unit(ms(7,$ms-base,$ms-ratio)) * 1rem);
$value-to-unit: map-deep-set($value-to-unit, font-size 8, strip-unit(ms(8,$ms-base,$ms-ratio)) * 1rem);
$value-to-unit: map-deep-set($value-to-unit, font-size 9, strip-unit(ms(9,$ms-base,$ms-ratio)) * 1rem);
$value-to-unit: map-deep-set($value-to-unit, font-size 10, strip-unit(ms(10,$ms-base,$ms-ratio)) * 1rem);


$font_display: get-css-size(font-family, display);
$font_accent: get-css-size(font-family, accent);
$font_ui: get-css-size(font-family, ui);
$font_copy: get-css-size(font-family, copy);
$font_mono: get-css-size(font-family, mono);

$global-radius-on: false;
$global-border-radius: 6px;
$value-to-unit: map-deep-set($value-to-unit, 'radius' 'radius', $global-border-radius);
$global-border-round: 25px;
$value-to-unit: map-deep-set($value-to-unit, 'radius' 'round', $global-border-round);
$basicSizeMultiplier: 1rem;

$h1-font-size: map-deep-get($value-to-unit, font-size, 5);
$h2-font-size: map-deep-get($value-to-unit, font-size, 4);
$h3-font-size: map-deep-get($value-to-unit, font-size, 3);
$h4-font-size: map-deep-get($value-to-unit, font-size, 2);
$h5-font-size: map-deep-get($value-to-unit, font-size, 1);
$h6-font-size: map-deep-get($value-to-unit, font-size, 0);

$font-size-base: $base-font-size-rem; // Assumes the browser default, typically `16px`
$font-size-lg: map-deep-get($value-to-unit, font-size, 2);
$font-size-sm: map-deep-get($value-to-unit, font-size, n1);

$logo: (
	primary: (
		url: '../img/cardiosmart/cardiosmart_primaryLogo.svg',
		alt: 'Logo of the CardioSmart',
		aspectratio: math.div(1, 3)
	),
	primary_alt: (
		url: '../img/cardiosmart/cardiosmart_primaryLogo-alt.svg',
		alt: 'Logo of the CardioSmart',
		aspectratio: math.div(1, 3)// 1/3 removing division symbols
	),
	condensed: (
		url: '../img/cardiosmart/cardiosmart_mobileLogo.svg',
		alt: 'Logo of the CardioSmart',
		aspectratio: math.div(1, 6)// 1/6 removing division symbols
	),
	condensed_alt: (
		url: '../img/cardiosmart/cardiosmart_mobileLogo-alt.svg',
		alt: 'Logo of the CardioSmart',
		aspectratio: math.div(1, 6)// 1/6 removing division symbols
	),
);
$seal: (
	normal: (
		url: '../img/cardiosmart/cardiosmart_seal.svg',
		alt: 'Seal of the CardioSmart',
		aspectratio: 1,
	),
	small: (
		url: '../img/cardiosmart/cardiosmart_sealCondensed.svg',
		alt: 'Seal of the CardioSmart',
		aspectratio: 1,
	),
	tiny: (
		url: '../img/cardiosmart/cardiosmart_sealCondensed-alt.svg',
		alt: 'Seal of the CardioSmart',
		aspectratio: 1,
	),
	normal_alt: (
		url: '../img/cardiosmart/cardiosmart_seal-reverse.svg',
		alt: 'Seal of the CardioSmart',
		aspectratio: 1,
	),
	small_alt: (
		url: '../img/cardiosmart/cardiosmart_sealCondensed-reverse.svg',
		alt: 'Seal of the CardioSmart',
		aspectratio: 1,
	),
	tiny_alt: (
		url: '../img/cardiosmart/cardiosmart_sealCondensed-alt-reverse.svg',
		alt: 'Seal of the CardioSmart',
		aspectratio: 1,
	),
);

/** Overriding the link color variables in scss **/
$link-color-light:					get-theme-color(primary, 3);
$link-color-light--hover:			get-theme-color(primary, 5);
$link-color-light--visited:			color.scale( get-theme-color(primary, 2), $saturation: -70%);
$link-color-dark:					get-theme-color(primary, -3);
$link-color-dark--hover:			get-theme-color(primary, -5);
$link-color-dark--visited:			color.scale( get-theme-color(primary, -2), $saturation: -70%);


$headline-color: get-theme-color(primary,-2);


/**  Build Comment: Overlay and alter for cardiosmart Branding 4.1.4x  **/ 
/** SCSS DOC:_brand.compile.scss **/
@import 'setup/config/__root.cssvars';

/**  Build Comment: Render Root Variables  **/ 
/** SCSS DOC: __var.output.scss **/
/** This needs to run later then brand **/

$margin_0: get-css-size(margin, 0);
$margin_1: get-css-size(margin, 1);
$margin_2: get-css-size(margin, 2);
$margin_3: get-css-size(margin, 3);
$margin_4: get-css-size(margin, 4);
$margin_5: get-css-size(margin, 5);
$margin_n1: get-css-size(margin, n1);
$margin_n2: get-css-size(margin, n2);
$margin_n3: get-css-size(margin, n3);
$margin_n4: get-css-size(margin, n4);
$margin_n5: get-css-size(margin, n5);
$padding_0: get-css-size(padding, 0);
$padding_1: get-css-size(padding, 1);
$padding_2: get-css-size(padding, 2);
$padding_3: get-css-size(padding, 3);
$padding_4: get-css-size(padding, 4);
$padding_5: get-css-size(padding, 5);
$border_square: 0;
$border_radius: get-css-size(border-radius, radius);
$border_round: get-css-size(border-radius, round);
$border_circle: get-css-size(border-radius, circle);
$border-width_0: get-css-size(border-width, 0);
$border-width_1: get-css-size(border-width, 1);
$border-width_2: get-css-size(border-width, 2);
$border-width_3: get-css-size(border-width, 3);
$border-width_4: get-css-size(border-width, 4);
$border-width_5: get-css-size(border-width, 5);
$font_display: var(--font-family_display);
$font_accent: var(--font-family_accent);
$font_ui: var(--font-family_ui);
$font_copy: var(--font-family_copy);
$font_mono: var(--font-family_mono);
$font_6: get-css-size(font-size, 6);
$font_5: get-css-size(font-size, 5);
$font_4: get-css-size(font-size, 4);
$font_3: get-css-size(font-size, 3);
$font_2: get-css-size(font-size, 2);
$font_1: get-css-size(font-size, 1);
$font_0: get-css-size(font-size, 0);
$font_n1: get-css-size(font-size, n1);
$font_n2: get-css-size(font-size, n2);
$font_n3: get-css-size(font-size, n3);
$font_n4: get-css-size(font-size, n4);
$font_n5: get-css-size(font-size, n5);
$font_bold: var(--font_bold);
$font_medium: var(--font_medium);
$font_xbold: var(--font_xbold);
$font_light: var(--font_light);
$font_regular: var(--font_regular);
$line-height_0: get-css-size(line-height, 0);
$line-height_1: get-css-size(line-height, 1);
$line-height_2: get-css-size(line-height, 2);
$line-height_3: get-css-size(line-height, 3);
$line-height_4: get-css-size(line-height, 4);
$line-height_5: get-css-size(line-height, 5);
/** SCSS DOC: _setup.none.scss **/

/**  Build Comment: Basic UC File  **/ 

/**  Utility Classes    *********************************/
/**  Logos  **/
@import "utility-css/__index";
/** SCSS DOC: __globalshame_uc.scss **/